<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        class="col-12"
        label="Tag"
        v-model="formData.tag">
      </sgv-input-text>

      <sgv-input-text
        class="col-12"
        label="Audio Address"
        v-model="formData.audioAddress">
      </sgv-input-text>

      <sgv-input-text
        class="col-12"
        label="Token"
        v-model="formData.token">
      </sgv-input-text>
    </div>

    <button
      class="btn btn-warning"
      @click="updateData">
      แก้ไข
    </button>
  </div>
</template>

<script>
import { CONFIG_DEVICE } from './graph'


export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        tag: '',
        audioAddress: '',
        token: ''
      }
    }
  },
  methods: {
    updateData () {
      this.$apollo.mutate({
        mutation: CONFIG_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          input: {
            tag: this.formData.tag,
            audioAddress: this.formData.audioAddress,
            token: this.formData.token
          }
        }
      }).then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
