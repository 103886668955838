import gql from 'graphql-tag'


const response = `
  tag audioAddress token
`

export const DETAIL_STATUS = (templateType) => gql`query DETAIL_STATUS ($deviceType: String!, $deviceId: Int!) {
  status: detail${templateType}Status (deviceType: $deviceType, deviceId: $deviceId) {${response}}
}`

export const CONFIG_DEVICE = (templateType) => gql`mutation CONFIG_DEVICE ($deviceType: String!, $deviceId: Int!, $input: ${templateType}ConfigInput!) {
  config: config${templateType} (deviceType: $deviceType, deviceId: $deviceId, input: $input) {${response}}
}`


export const SPEAK_DEVICE = (templateType) => gql`mutation SPEAK_DEVICE ($deviceType: String!, $deviceId: Int!, $message: String!) {
  result: speak${templateType} (deviceType: $deviceType, deviceId: $deviceId, message: $message)
}`

export const BELL_DEVICE = (templateType) => gql`mutation BELL_DEVICE ($deviceType: String!, $deviceId: Int!) {
  result: bell${templateType} (deviceType: $deviceType, deviceId: $deviceId)
}`
